import * as React from 'react';
import { Link } from 'react-router-dom';
import { useLang } from '../../context/LangContext';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Popover from '@mui/material/Popover';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styles from './styles.module.css';

function Navbar() {
  const { lang, setLang } = useLang();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLang, setSelectedLang] = React.useState(lang);
  const [isLangListOpen, setLangListOpen] = React.useState(false);
  const [translations, setTranslations] = React.useState(null);

  React.useEffect(() => {
    import(`../../lang/${lang}`).then((module) => {
      setTranslations(module.default);
    });
  }, [lang]);

  const handleLangChange = (language) => {
    setLang(language);
    setSelectedLang(language);
    setLangListOpen(false);
  };

  const handleGamesMouseEnter = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleGamesMouseLeave = (e) => {
    if (e.relatedTarget && typeof e.relatedTarget.closest === 'function') {
      if (!e.relatedTarget.closest('.MuiPopover-root')) {
        setAnchorEl(null);
      }
    } else {
      setAnchorEl(null);
    }
  };
  

  if (!translations) return null;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" className={`${styles.appBar} ${styles.gameBackground}`}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6" noWrap component="div">
            <Link to="/" className={styles.logoLink}>
              <img src="/logo_onGrey.png" alt="Logo" className={styles.logo} />
            </Link>
          </Typography>
          <div>
            {/*<Button component={Link} to="/" sx={{ color: '#fff !important' }}>*/}
            {/*  {translations.navbar.Home}*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*  aria-haspopup="true"*/}
            {/*  onMouseEnter={handleGamesMouseEnter}*/}
            {/*  onMouseLeave={handleGamesMouseLeave}*/}
            {/*  sx={{ color: '#fff !important' }}*/}
            {/*>*/}
            {/*  {translations.navbar.Games}*/}
            {/*  <img src='/icones/fleche-vers-le-bas.png' alt="Flèche vers le bas" className={styles.arrowIcon} />*/}
            {/*</Button>*/}
            {/*<Popover*/}
            {/*  anchorEl={anchorEl}*/}
            {/*  open={Boolean(anchorEl)}*/}
            {/*  onClose={handleGamesMouseLeave}*/}
            {/*  anchorOrigin={{*/}
            {/*    vertical: 'bottom',*/}
            {/*    horizontal: 'left',*/}
            {/*  }}*/}
            {/*  transformOrigin={{*/}
            {/*    vertical: 'top',*/}
            {/*    horizontal: 'left',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Box sx={{ p: 2 }}>*/}
            {/*    <Typography>*/}
            {/*      <Link to="/games/soul-reaper" className={styles.link}>*/}
            {/*        {translations.home.soulReaper.title}*/}
            {/*      </Link>*/}
            {/*    </Typography>*/}
            {/*    <Typography>*/}
            {/*      <Link to="/games/arsim" className={styles.link}>*/}
            {/*        {translations.home.arsim.title}*/}
            {/*      </Link>*/}
            {/*    </Typography>*/}
            {/*  </Box>*/}
            {/*</Popover>*/}
            {/*<Button component="a" href="https://medium.com/power-level" target='_Blank' sx={{ color: '#fff !important' }}>*/}
            {/*  {translations.navbar.News}*/}
            {/*</Button>*/}
            {/*<Button component={Link} to="/contact" sx={{ color: '#fff !important' }}>*/}
            {/*  {translations.navbar.Contact}*/}
            {/*</Button>*/}
          </div>
          {/*<div>*/}
          {/*  <Button*/}
          {/*    aria-haspopup="true"*/}
          {/*    onClick={(e) => setLangListOpen(true)}*/}
          {/*    sx={{ color: '#fff !important' }}*/}
          {/*  >*/}
          {/*    {selectedLang === 'fr' ? (*/}
          {/*      <img src="/icones/french_guiana_icon_127817.png" alt="French Flag" className={styles.languageFlag} />*/}
          {/*    ) : (*/}
          {/*      <img src="/icones/royaume-uni.png" alt="English Flag" className={styles.languageFlag} />*/}
          {/*    )}*/}
          {/*  </Button>*/}
          {/*  <Popover*/}
          {/*    anchorEl={anchorEl}*/}
          {/*    open={isLangListOpen}*/}
          {/*    onClose={() => setLangListOpen(false)}*/}
          {/*    anchorOrigin={{*/}
          {/*      vertical: 'bottom',*/}
          {/*      horizontal: 'right',*/}
          {/*    }}*/}
          {/*    transformOrigin={{*/}
          {/*      vertical: 'top',*/}
          {/*      horizontal: 'right',*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <Box className={styles.langMenu} sx={{ p: 2 }}>*/}
          {/*      <Button onClick={() => handleLangChange('fr')}>*/}
          {/*        <img src="/icones/french_guiana_icon_127817.png" alt="French Flag" className={styles.languageFlag} />*/}
          {/*      </Button>*/}
          {/*      <Button onClick={() => handleLangChange('en')}>*/}
          {/*        <img src="/icones/royaume-uni.png" alt="English Flag" className={styles.languageFlag} />*/}
          {/*      </Button>*/}
          {/*    </Box>*/}
          {/*  </Popover>*/}
          {/*</div>*/}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;
