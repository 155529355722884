import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { useLang } from '../../context/LangContext';
import { Link } from 'react-router-dom';

function Footer() {
  const { lang } = useLang();
  const [t, setT] = useState(null);

  // onMounted
  useEffect(() => {
    import(`../../lang/${lang}`).then((module) => {
      setT(module.default);
    });
  }, [lang]);

  if (!t) return <></>;

  return (
      <footer className={styles.footer}>
        <p className={styles.footerDisclaimer}>
          <p>Email : <a href="mailto:info@powerlevelstudio.com">info@powerlevelstudio.com</a></p>
          {t.footer.copyright}
        </p>
        <div className={styles.footerContent}>
          {/*<div className={styles.contactInfo}>*/}
          {/*  <h3>{t.footer.contact}</h3>*/}
          {/*  <p>216-4035 St-Ambroise Street</p>*/}
          {/*  <p>Montreal, QC, Canada, H4C 2E1</p>*/}
          {/*  <p>Email : <a href="mailto:info@powerlevelstudio.com">info@powerlevelstudio.com</a></p>*/}
          {/*</div>*/}
          <div className={styles.newsSection}>
            {/*<div className={styles.socialMediaContainer}>*/}
            {/*  <div className={styles.socialMediaTrapezoid} style={{ backgroundColor: '#BF7E04' }}>*/}
            {/*    <img src="/social media/linkedin.png" alt="LinkedIn" className={styles.socialMediaIcon} />*/}
            {/*  </div>*/}
            {/*  <Link to="/contact" className={styles.socialMediaTrapezoid} style={{ backgroundColor: '#A64F03' }}>*/}
            {/*    <img src="/social media/enveloppe.png" alt="message" className={styles.socialMediaIcon} />*/}
            {/*  </Link>*/}
            {/*  <div className={styles.socialMediaTrapezoid} style={{ backgroundColor: '#591902' }}>*/}
            {/*    <img src="/social media/facebook.png" alt="Facebook" className={styles.socialMediaIcon} />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </footer>
  );
}

export default Footer;
