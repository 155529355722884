import React, { useEffect, useState } from 'react';
import styles from './Home.module.css';
import { useLang } from '../../context/LangContext';
import { Link } from 'react-router-dom';

function Home() {
  const { lang } = useLang();
  const [t, setT] = useState(null);
  const [selectedGame, setSelectedGame] = useState('arsim');

  // onMounted
  useEffect(() => {
    import(`../../lang/${lang}`).then((module) => {
      setT(module.default)
    })
  }, [lang]);

  if (!t)
    return <></>;

  const handleGameClick = (game) => {
    setSelectedGame(game);
  }

  return (
      <div className={styles.homeContainer}>

        <div className={`${styles.aboutUsSection} ${styles.withMargin}`}>
          <div className={styles.aboutUsContent}>
            <div className={styles.newsImage}>
              <img src="/images/arsim/Image5.jpg" alt="Nos actualités"/>
            </div>
            <div className={styles.newsContent}>
              <h2>
                <Link to="https://medium.com/power-level" className={styles.linkText}>
                  News
                </Link>
              </h2>
              <p>
                <Link to="https://medium.com/power-level" className={styles.linkText}>
                  See what's new in the Power Level blog
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className={styles.gameSectionContainer}>
          <h1>Our Games</h1>
          <div className={styles.ImageGames}>
            <div
                className={`${styles.gameSection} ${selectedGame === 'arsim' ? styles.active : ''}`}
                onClick={() => handleGameClick('arsim')}
            >
              <img
                  src={`/images/BackgrundImages/${selectedGame === 'arsim' ? 'Arsim Backgrund.png' : 'Arsim BackgrundNotBorder.png'}`}
                  alt="Arsim"/>
            </div>
            <div
                className={`${styles.gameSection} ${selectedGame === 'soulReaper' ? styles.active : ''}`}
                onClick={() => handleGameClick('soulReaper')}
            >
              <img
                  src={`/images/BackgrundImages/${selectedGame === 'soulReaper' ? 'Soul Reaper Backgrund.png' : 'Soul Reaper BackgrundNotBorder.png'}`}
                  alt="Soul Reaper"/>
            </div>
          </div>
          <div className={`${styles.textSectionGame} ${selectedGame ? styles.active : ''}`}>
            {selectedGame && (
                <div className={styles.gameInfo}>
                  <h2 className={styles.gamesTitle}>{t.home[selectedGame].title}</h2>
                  <p>{t.home[selectedGame].description}</p>

                </div>
            )}
          </div>
        </div>

        <div className={styles.connectSection}>
          <div className={styles.connectContent}>
            <div>
              {/* <img src="/images/app-icon_unreap.png" alt="Connect" className={styles.connectImage} /> */}
            </div>
            {/*<div>*/}
            {/*  <p>{t.home.connectWithUs}</p>*/}
            {/*  <Link to="/contact" className={styles.contactButton}>*/}
            {/*    {t.home.button.contact}*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
  );
}

export default React.memo(Home);
