import React, { useState, useEffect } from "react";
import { Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ImageList, ImageListItem } from "@mui/material";
import gameImage from "../../data/gameImage";
import { useLang } from '../../context/LangContext'; 
import styles from "./styles.module.css";

function GameDetailsArsim() {
  const { lang } = useLang();
  const [t, setT] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    import(`../../lang/${lang}`).then((module) => {
      setT(module);
    });
  }, [lang]);

  useEffect(() => {
    const handleScroll = (event) => {
      const imageSection = document.querySelector(`.${styles.imageSection}`);
      if (event.deltaY === 0 || Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
        event.preventDefault();
        imageSection.scrollLeft += event.deltaX;
      }
    };

    window.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);

  if (!t) return null;

  const { images } = gameImage["arsim"];
  const itemsPerPage = 3;
  const totalPages = Math.ceil(Object.keys(images).length / itemsPerPage);

  const handleClickPrevious = () => {
    setCurrentPage((prevPage) => Math.max(0, prevPage - 1));
  };

  const handleClickNext = () => {
    setCurrentPage((prevPage) => Math.min(totalPages - 1, prevPage + 1));
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedItems = Object.keys(images).slice(startIndex, endIndex).map((key) => ({
    img: images[key],
    title: key,
  }));

  return (
    <React.Fragment>
      <div className={`${styles.titleContainer} `}>
        <div className={styles.overlay}></div>
        <img src="/images/arsim/arsimTitle.png" alt="Title" className={styles.titleImage} />
        <Button component={Link} to="/game-page" variant="contained" className={styles.playNowButton}>
          Play Now
        </Button>
      </div>

      <div className={`${styles.blackBackground} `}>
        <Typography variant="h4">{gameImage["arsim"].title}</Typography>
        <Typography variant="body1">{t.GameDetailsArsim.description}</Typography>
      </div>

      <div className={`${styles.imageSection} `}>
        <div className={styles.imageSlider}>
          <div className={styles.imageListContainer}>
            <ImageList className={`${styles.imageList}`} cols={3} > 
              {displayedItems.map((item, index) => (
                <ImageListItem key={item.img} className={styles.imageListItem}>
                  <img
                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    alt={item.title}
                    loading="lazy"
                    className={styles.fullSizeImage}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
          <div className={`${styles.pagesSlider}`}>
            <button onClick={handleClickPrevious} disabled={currentPage === 0}>&lt;</button>
            <button onClick={handleClickNext} disabled={currentPage === totalPages - 1}>&gt;</button>
          </div>
        </div>
      </div>

      <div className={`${styles.smallImageSection}`}>
        <div className={styles.features}>
          <Typography variant="h4">Features</Typography>
          <div className={styles.smallImageList}>
            <div className={styles.row}>
              <div className={styles.smallImageContainer}>
                <div className={styles.image}>
                  <img src="/icones/digital-economy.png" alt="Digital Economy" />
                </div>
                <Typography variant="body2">Player Driven Economy</Typography>
              </div>
              <div className={styles.smallImageContainer}>
                <div className={styles.image}>
                  <img src="/icones/equipe.png" alt="Equipe" />
                </div>
                <Typography variant="body2">Community First</Typography>
              </div>
              <div className={styles.smallImageContainer}>
                <div className={styles.image}>
                  <img src="/icones/maison.png" alt="Maison" />
                </div>
                <Typography variant="body2">Open World Housing</Typography>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.smallImageContainer}>
                <div className={styles.image}>
                  <img src="/icones/ocean.png" alt="Ocean" />
                </div>
                <Typography variant="body2">Ocean Navigation</Typography>
              </div>
              <div className={styles.smallImageContainer}>
                <div className={styles.image}>
                  <img src="/icones/outils-de-dessin.png" alt="Outils de Dessin" />
                </div>
                <Typography variant="body2">Unlimited Customization</Typography>
              </div>
              <div className={styles.smallImageContainer}>
                <div className={styles.image}>
                  <img src="/icones/political.png" alt="Political System" />
                </div>
                <Typography variant="body2">Political System</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.learnMoreSection}`}>
        <div className={styles.learnText}>
          <Typography variant="h5">Learn more about Arsim</Typography>
          <Button component={Link} to="/learn-more" variant="contained" className={styles.learnMoreButton}>
            Learn More
          </Button>
        </div>
        {/* <img src="/images/arsim/Image6.jpg" alt="Learn More" className={styles.learnMoreImage} /> */}
      </div>
    </React.Fragment>
  );
}

export default GameDetailsArsim;





























