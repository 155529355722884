import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import Home from "./pages/Home/Home";
import News from "./pages/News/News"
import Contact from './pages/Contact/Contact'
import GameDetailsArsim from "./pages/GameDetailsArsim/GameDetailsArsim";
import GameDetailsSoulReaper from "./pages/GameDetailsSoulReaper/GameDetailsSoulReaper";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { LangProvider } from "./context/LangContext"; 

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <LangProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/news" element={<News/>} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/games/arsim" element={<GameDetailsArsim />} />
            <Route path="/games/soul-reaper" element={<GameDetailsSoulReaper />} />
          </Routes>
          <Footer />
        </Router>
      </LangProvider>
    </I18nextProvider>
  );
}

export default App;
