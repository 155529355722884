import styles from "./styles.module.css";


import React from "react";

function News() {
  return (
    <div>
      <h1>News Page</h1>
      <p>This is the news page content.</p>
    </div>
  );
}

export default News; // Export the component News as default
