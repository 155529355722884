// i18n/i18n.js

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enTranslation from '../lang/en.json';
import frTranslation from '../lang/fr.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      fr: {
        translation: frTranslation,
      },
    },
    fallbackLng: 'en', 
    detection: {
      order: ['localStorage', 'navigator'],
    },
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;
