import React, { useEffect, useState } from 'react';
import contactStyles from './contact.module.css';
import { useLang } from '../../context/LangContext';

function Contact() {
  const { lang } = useLang();
  const [t, setT] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    import(`../../lang/${lang}`).then((module) => {
      setT(module)
    })
  }, [lang]);

  if (!t)
    return <></>;

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logique de soumission du formulaire
    setIsSubmitted(true);
  };

  return (
    <div className={contactStyles.contactContainer}>
      <h2 className={contactStyles.title}>{t.contact.title}</h2>
      <p className={contactStyles.text}>{t.contact.text}</p>

      {isSubmitted ? (
        <p className={contactStyles.submittedMessage}>Votre message a été envoyé avec succès!</p>
      ) : (
        <form className={contactStyles.form} onSubmit={handleSubmit}>
          <div className={contactStyles.inputGroup}>
            <div className={contactStyles.labelGroup}>
              <label>{t.contact.name}</label>
              <label>{t.contact.email}</label>
            </div>
            <div className={contactStyles.labelGroup}>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={contactStyles.inputText}
                placeholder={t.contact.name}
              />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={contactStyles.inputText}
                placeholder={t.contact.email}
              />
            </div>
          </div>
          <div className={contactStyles.inputGroup}>
            <label>{t.contact.subject}</label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className={contactStyles.inputText}
              placeholder={t.contact.subject}
            />
          </div>
          <div className={contactStyles.inputGroup}>
            <label>{t.contact.yourMessage}</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className={`${contactStyles.inputText} ${contactStyles.textarea}`}
              placeholder={t.contact.yourMessage}
            />
          </div>
          <button type="submit" className={contactStyles.button}>{t.contact.sendMessage}</button>
        </form>
      )}
    </div>
  );
}

export default Contact;
