// data/gameImage.js
const gameImage = {
  'soul-reaper': {
    title: 'Soul Reaper',
    description: 'Description of Soul Reaper...',
  images: {
      boxArtCompare: '/images/soul-reaper/box-art-compare.jpg',
      boxArtFinal: '/images/soul-reaper/box-art-final.jpg',
      boxArtFinal2: '/images/soul-reaper/box-art-final2.jpg',
      boxArt: '/images/soul-reaper/box-art.jpg',
      boxArtPSD: '/images/soul-reaper/box-art.jpg',
      boxArt2: '/images/soul-reaper/box-art2.jpg',
      boxArt3: '/images/soul-reaper/box-art3.jpg',
      srNew1: '/images/soul-reaper/SR new 1.png',
      srNew1920X1080: '/images/soul-reaper/SR new 1920X1080.png',
      srNew5896X3317: '/images/soul-reaper/SR new 5896X3317.png',

    },
  },
  arsim: {
    title: 'Arsim',
    description: 'Description of Arsim...',
    images: {

      image1: '/images/arsim/Image1.jpg', //c'est nomé front vient avec un montre qui tiens du feux 
      image2: '/images/arsim/Image2.jpg', // image d'une ville
      image3: '/images/arsim/Image3.png', // combat screen
      image4: '/images/arsim/Image4.jpg',  // autre image combat screen
      image5: '/images/arsim/Image5.jpg', // monsttre ninja avec une manchette (couteau)
      image6: '/images/arsim/Image6.jpg', // un monsoeur qui jour à la guitar
      image7: '/images/arsim/Image7.jpg', //un personnage qui a des cornes et qui a mit un gros sac deriere
      image8: '/images/arsim/Image8.jpg', // des montagnes et des sapin de neige

    },
  },
};
export default gameImage;